import React from 'react';
import {useEffect, useRef, useState} from 'react';

const Canvas = (props) => {
    const {text, styling, renderStyle, playing, getCanvasWidth} = props;
    let ref = useRef();
    let playbackRate = 1;
    let fontSize = Number(styling['fontSize'].replace('px',''));
    let fontFamily = styling['fontFamily'] ? styling['fontFamily'] : 'Arial';
    let fontWeight = styling['fontWeight'] ? styling['fontWeight'] : 'Normal';
    let textColor = renderStyle['color'] ? renderStyle['color'] : '#000';
    let baseColor = renderStyle['2c&H'] ? renderStyle['2a&H'] ? `${renderStyle['2c&H']+renderStyle['2a&H']}` :  `${renderStyle['2c&H']}` : 'transparent';
    let transform = renderStyle['transform'] ? renderStyle['transform'] : '';
    let textShadow = renderStyle['textShadow'] ? renderStyle['textShadow'] : '';
    let animation = `run-text ${styling['karaoke']/(100*playbackRate)}s linear forwards ${playing ? 'running' : 'paused'}`;
    let keyframes = '@keyframes run-text {from { width: 0; overflow: hidden } to { width: 100%; overflow: hidden }}';
    let whiteSpace = 'nowrap';
    let textAlign = 'left';
    let [width, setWidth] = useState(0);
    let styleSheet = document.styleSheets[0];
    let txtWidth;

    useEffect(() => {
        let canvas = ref.current;
        let context = canvas.getContext('2d');

        context.font = `${fontSize}px ${fontWeight} ${fontFamily}`;
        txtWidth = context.measureText(text).width;
        setWidth(txtWidth);
        getCanvasWidth(txtWidth);
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    },[]);

    return (
        <div style={{width}}>
            <div
                style={{
                    fontFamily,
                    fontSize,
                    color: baseColor,
                    // transform,
                    textShadow,
                    whiteSpace,
                    textAlign,
                    position: 'absolute',
                    top: '0',
                    zIndex: '-1'
                }}
            >
                {text}
            </div>
            <div
                data-text={text}
                style={{
                    fontFamily,
                    fontSize,
                    color: textColor,
                    // transform,
                    textShadow,
                    animation,
                    whiteSpace,
                    textAlign
                }}
            >
                {text}
            </div>
            <canvas ref={ref} style={{display: 'none'}}/>
        </div>

    )
};

export default Canvas;