import React, {useEffect, useState} from 'react';
import {Tooltip, withStyles} from "@material-ui/core";
import {useIntl} from "react-intl";

export const ICON_CONFIG = {
    goBack: {icon: "btn_cancel", tooltip: "public.go-back", gtmAction: "Exit"},
    seekBack: {icon: "btn_skip_8", tooltip: "play-one.backward-title", gtmAction: "Seek BackWard"},
    seekForward: {icon: "btn_skip_30", tooltip: "play-one.forward-title", gtmAction: "Seek Forward"},
    prev: {icon: "btn_pre_section", tooltip: "public.previous-title", gtmAction: "Play Previous"},
    next: {icon: "btn_next_section", tooltip: "public.next-title", gtmAction: "Play Next"},
    play: {icon: "btn_play", tooltip: "public.play-title", gtmAction: "Play"},
    pause: {icon: "btn_pause", tooltip: "public.pause-title", gtmAction: "Pause"},
    enterFullScreen: {icon: "btn_full_screen", tooltip: "public.enter-full-screen-title", gtmAction: "Enter Fullscreen"},
    exitFullScreen: {icon: "btn_min_screen", tooltip: "public.exit-full-screen-title", gtmAction: "Exit Fullscreen"},
    setting: {icon: "btn_setting", tooltip: "play-one.setting", gtmAction: "Open Setting"},
    mute: {icon: "icon-volume0", tooltip: "public.mute-off-title", gtmAction: "UnMute"}, //toggle
    unmute: {icon: "icon-volume3", tooltip: "public.mute-title", gtmAction: "Mute"}, //toggle
    DOMplay: {icon: "btn_play", gtmAction: "DOM play"},
    //photo
    showThumb: {icon: "btn_show_thumb", tooltip: "photo-gallery.show-strip", gtmAction: "Close Thumbnails"}, //toggle
    hideThumb: {icon: "btn_hide_thumb", tooltip: "photo-gallery.show-strip", gtmAction: "Open Thumbnails"}, //toggle
    nextPage: {icon: "btn_more", tooltip: "photo-gallery.next-page-title", gtmAction: "Slider Next Page"},
    prevPage: {icon: "btn_backmore", tooltip: "photo-gallery.previous-page-title", gtmAction: "Slider Previous Page"},
    //music
    repeat: {icon: "btn_music_repeat", tooltip: "music-play.repeat-all-title", gtmAction: "Repeat All"}, //toggle
    repeatAll: {icon: "btn_music_repeatALL", tooltip: "music-play.repeat-current-title", gtmAction: "Repeat One"}, //toggle
    repeatOne: {icon: "btn_music_repeat1", tooltip: "music-play.repeat-off-title", gtmAction: "UnRepeat"}, //toggle
    unShuffle: {icon: "btn_music_Shuffle", tooltip: "music-play.shuffle-title", gtmAction: "Shuffle"}, //toggle
    shuffle: {icon: "btn_music_Shuffle", tooltip: "music-play.shuffle-off-title", gtmAction: "UnShuffle"}, //toggle
    close: {icon: "btn_close", tooltip: "music-play.close-title", gtmAction: "Close"},
    //single music (share)
    singleRepeat: {icon: "btn_music_repeat", tooltip: "public.repeat-title", gtmAction: "Repeat Single"},
    singleRepeatOne: {icon: "btn_music_repeat1", tooltip: "music-play.repeat-off-title", gtmAction: "Repeat Single"},
    stop: {icon: "btn_stop", tooltip: "share-music.stop", gtmAction: "Stop"},
};

const ImgIconButton = props => {
    const {formatMessage: f} = useIntl();
    const {classes, name, onClick, style, label} = props;
    const config = ICON_CONFIG[props.name];
    const tooltip = config.tooltip? f({id: config.tooltip}): "";
    const ref = document.getElementById('player-wrapper');
    
    function handleClick() {
        if(!props.disabled) {
            onClick();
        }
    }

    return(
        <Tooltip key={tooltip} title={tooltip} PopperProps={{container: ref}} open={!tooltip? false: undefined}
                 classes={props.hideTooltip? {popper: classes.invisible}:null} >
            <i id={`player_${name}`} onClick={handleClick} style={style} className={classes.btn} data-label={label} />
        </Tooltip>
    )
};

const iconSrc = (name, type = "n") => require(`../../assets/player/${ICON_CONFIG[name].icon}_${type}@2x.png`);
export default withStyles(theme => ({
    btn: {
        display: "block",
        height: 48, //3em
        width: 48, //3em
        padding: 5,  // 0.3125em for click
        margin: "0 1vw",
        cursor: props => !props.disabled? "pointer": "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundOrigin: "content-box",
        backgroundPosition: "center",
        backgroundImage: props => `url(${iconSrc(props.name, props.disabled? "d": props.active? "p": props.selected? "s": "n")})`,
        '&:hover': {
            backgroundImage: props => !(props.disabled||props.active||props.disableHover||props.selected) && `url(${iconSrc(props.name, "h")})`,
        },
        '&:active': {
            backgroundImage: props => !(props.disabled||props.active||props.disablePress||props.selected) && `url(${iconSrc(props.name, "p")})`,
        },
    },
    invisible: {
        visibility: "hidden"
    },
}))(ImgIconButton);