import React, {Component} from 'react'
import {propTypes, defaultProps} from './props'
import {getConfig, omit, isEqual} from './utils'
import Player from './Player'
import Subtitle from './Subtitle';

const SUPPORTED_PROPS = Object.keys(propTypes)

export default class ReactPlayerLite extends Component {
    static displayName = 'ReactPlayerLite';
    static propTypes = propTypes;
    static defaultProps = defaultProps;
    static canPlay = url => {
        if (this.player.canPlay(url)) {
            return true
        }
        return false
    }
    config = getConfig(this.props, defaultProps, true)
    state = {
        showPreview: !!this.props.light
    }

    componentDidMount() {
        if (this.props.progressFrequency) {
            const message = 'ReactPlayerLite: %cprogressFrequency%c is deprecated, please use %cprogressInterval%c instead'
            console.warn(message, 'font-weight: bold', '', 'font-weight: bold', '')
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
    }

    componentWillUpdate(nextProps) {
        this.config = getConfig(nextProps, defaultProps)
    }

    getDuration = () => {
        if (!this.player) return null
        return this.player.getDuration()
    }
    getCurrentTime = () => {
        if (!this.player) return null
        return this.player.getCurrentTime()
    }
    getIsPlaying = () => {
        if (!this.player) return null
        return this.player.isPlaying
    }
    getSecondsLoaded = () => {
        if (!this.player) return null
        return this.player.getSecondsLoaded()
    }
    seekTo = (fraction, type) => {
        if (!this.player) return null
        this.player.seekTo(fraction, type)
    }
    onReady = () => {
        this.props.onReady(this)
    }
    wrapperRef = wrapper => {
        this.wrapper = wrapper
    }
    activePlayerRef = player => {
        this.player = player
    }

    sortPlayers(a, b) {
        // Retain player order to prevent weird iframe behaviour when switching players
        if (a && b) {
            return a.key < b.key ? -1 : 1
        }
        return 0
    }

    render() {
        const {url, controls, style, width, height, light, wrapper: Wrapper, subtitles, fileExtension, currentTime, currentTimeDelayed, mediaType} = this.props;
        const otherProps = omit(this.props, SUPPORTED_PROPS);
        return (
            <Wrapper ref={this.wrapperRef} style={{...style, width, height}} {...otherProps}>
                <Player
                    {...this.props}
                    ref={this.activePlayerRef}
                    config={this.config}
                    onReady={this.onReady}
                />
                {mediaType !== "Music" &&
                    <Subtitle
                        subtitles={subtitles}
                        fileExtension={fileExtension}
                        currentTime={currentTime}
                        currentTimeDelayed={currentTimeDelayed}
                        playing={this.props.playing}
                        barStatus={this.props.barStatus}
                        subtitleSeek={this.props.subtitleSeek}
                        width={width}
                        height={height}
                    />
                }
            </Wrapper>
        )
    }
}
