import React, { useEffect, useState, useLayoutEffect } from "react";
import Canvas from "./Canvas";

const Subtitle = props => {
    // console.log('%c%s','font-size:20px;background:yellow;color:black;',props);
    // console.log(props.subtitles);
    // console.log(props.currentTime);
    const [subtitleText, setSubtitleText] = useState("");
    const [subtitleIndex, setSubtitleIndex] = useState(-1);
    const [subtitles, setSubtitles] = useState("");
    const [fontSize, setFontSize] = useState("4.5vmin");
    const [numOfInitStyles, setNumOfInitStyles] = useState(0);
    const defaultStyle = {
        fontSize,
        width: "100%",
        textAlign: "center",
        fontWeight: "bold",
        display: "inline-block",
        textShadow: "rgb(0, 0, 0) 1px 1px, rgb(0, 0, 0) 1px 0px, rgb(0, 0, 0) 0px 1px",
        filter: "drop-shadow(rgb(0, 0, 0) 1.5px 1.5px)"
    };
    let fileExtension = props.fileExtension;
    let currentTime = props.currentTime !== "" ? props.currentTime : "";
    let hlsHeight = props.height;
    let hlsWidth = props.width;
    const [customStyle, setCustomStyle] = useState({});
    const [htmlClass, setHtmlClass] = useState("");
    const [id, setId] = useState("");
    const [tag, setTag] = useState("");
    const [ssaStyle, setSsaStyle] = useState("");
    const [assStyle, setAssStyle] = useState("");
    const [assOverrideSubtitleArray, setAssOverrideSubtitleArray] = useState([]);
    const [overrideTextArray, setOverrideTextArray] = useState([]);
    const [normalTextArray, setNormalTextArray] = useState([]);
    const [playX, setPlayX] = useState(null);
    const [playY, setPlayY] = useState(null);
    const [tempOverrideTextArray, setTempOverrideTextArray] = useState([]);
    const [tempNormalTextArray,setTempNormalTextArray] = useState([]);
    const [subtitleSeekTime, setSubtitleSeekTime] = useState(-1);
    const [canvasWidth, setCanvasWidth] = useState(0);
    let positionX = null;
    let positionY = null;
    let moveDuration = 0, fadeDuration = 0, tDuration = 0;
    let filterShadowColor = "";
    let textShadowColor = "";
    let fadeType = "";
    let fadeDelay = 0;
    let moveDelay = 0;
    let tDelay = 0;
    let tAccel = 0;

    useEffect(()=>{
        if(props.subtitleSeek >= 0)
            setSubtitleSeekTime(props.subtitleSeek);
    },[props.subtitleSeek]);

    useEffect(() => {
        setNumOfInitStyles(document.styleSheets[0].rules.length);
    }, []);
    useEffect(() => {
        setTempNormalTextArray([]);
        setNormalTextArray([]);
        setTempOverrideTextArray([]);
        setOverrideTextArray([]);
        if (props.subtitles && (fileExtension === "ass" || fileExtension === "ssa")) {
            setTempNormalTextArray(props.subtitles.normalArray);
            setTempOverrideTextArray(props.subtitles.overrideArray);
            let tempX = props.subtitles.info.PlayResX
                ? parseInt(props.subtitles.info.PlayResX, 10)
                : 0;
            let tempY = props.subtitles.info.PlayResY
                ? parseInt(props.subtitles.info.PlayResY, 10)
                : 0;
            if (tempX > 0 && tempY > 0) {
                setPlayX(tempX);
                setPlayY(tempY);
            } else if (tempX <= 0 && tempY <= 0) {
                setPlayX(384);
                setPlayY(288);
            } else if (tempX === 1280 && tempY <= 0) {
                setPlayX(1280);
                setPlayY(1024);
            } else if (tempX <= 0 && tempY === 1024) {
                setPlayX(1280);
                setPlayY(1024);
            } else if (tempX > 0 && tempY <= 0) {
                setPlayX(tempX);
                setPlayY(tempX * (3 / 4));
            } else if (tempX <= 0 && tempY > 0) {
                setPlayX(tempY * (4 / 3));
                setPlayY(tempY);
            }
        } else if (props.subtitles && fileExtension === "srt"){
            setTempNormalTextArray(props.subtitles.subtitleArray);
        }
    }, [props.subtitles, subtitles, fileExtension]);

    useLayoutEffect(() => {
        if(props.subtitles.styles){
            if(fileExtension === "ssa" ){
                if(props.subtitles.styles[ssaStyle] && props.subtitles.styles[ssaStyle].fontSize)
                    setFontSize(((props.subtitles.styles[ssaStyle].fontSize * hlsHeight) / playY).toFixed(2) + "px")
            }
            else if(fileExtension === "ass"){
                if(props.subtitles.styles[assStyle] && props.subtitles.styles[assStyle].fontSize)
                    setFontSize(((props.subtitles.styles[assStyle].fontSize * hlsHeight) / playY).toFixed(2) + "px")
            }
        } else {
            setFontSize(0.05*hlsHeight + 'px')
        }
    }, [hlsWidth, hlsHeight]);

    useEffect(() => {
        if (props.subtitles && fileExtension !== "ass" && fileExtension !== "ssa" && fileExtension !== "srt") {
            setSubtitles(props.subtitles.subtitleArray);
        }
    }, [props.subtitles, subtitles, fileExtension]);

    useEffect(() => {
        if(fileExtension === "ass" || fileExtension === "ssa"){
            if(tempOverrideTextArray.length !== 0 && findAllOccurrences(currentTime, tempOverrideTextArray) !== []){
                setAssOverrideSubtitleArray(findAllOccurrences(currentTime, tempOverrideTextArray));
            }
            if(tempNormalTextArray.length !== 0){
                setNormalTextArray(findAllOccurrences(currentTime, tempNormalTextArray));
            }
        } else if (fileExtension === "srt") {
            setNormalTextArray(findAllOccurrences(currentTime, tempNormalTextArray));
        }
    }, [currentTime, JSON.stringify(tempOverrideTextArray), JSON.stringify(tempNormalTextArray)]);

    useEffect(() => {
        if(assOverrideSubtitleArray.length > 0){
            setOverrideTextArray(assOverrideSubtitleArray);
        }
        return () => {
            setOverrideTextArray([]);
        }
    },[JSON.stringify(assOverrideSubtitleArray)]);

    useEffect(() => {
        if (subtitles && fileExtension !== "ass" && fileExtension !== "ssa" && fileExtension !== "srt") {
            setSubtitleIndex(
                binarySearch(subtitles, 0, subtitles.length - 1, currentTime)
            );
        }
    }, [currentTime]);

    useEffect(() => {
        if (subtitleIndex < 0 || subtitles[subtitleIndex] === undefined) {
            setSubtitleText("");
        } else {
            if (fileExtension === "smi") {
                setSubtitleText(subtitles[subtitleIndex].content_info[0].text);
                setHtmlClass(subtitles[subtitleIndex].content_info[0].class);
                setId(subtitles[subtitleIndex].content_info[0].id);
                setTag(subtitles[subtitleIndex].content_info[0].tag);
            } else {
                setSubtitleText(subtitles[subtitleIndex].text);
            }
        }
    }, [subtitleIndex]);

    useEffect(() => {
        if(fileExtension !== "srt"){
            if (overrideTextArray !== [] && overrideTextArray !== undefined) {
                overrideTextArray.forEach(item => {
                    if (item !== undefined) fileExtension === "ass" ? setAssStyle(item.style) : setSsaStyle(item.style);
                });
            }
            if (normalTextArray !== [] && normalTextArray !== undefined) {
                normalTextArray.forEach(item => {
                    if (item !== undefined) fileExtension === "ass" ? setAssStyle(item.style) : setSsaStyle(item.style);
                });
            }
        }
    }, [overrideTextArray, normalTextArray]);

    useEffect(() => {
        if (props.subtitles) {
            if (fileExtension === "subviewer") {
                if (props.subtitles.styles.fontWeight === "bd")
                    props.subtitles.styles.fontWeight = "bold";
                setCustomStyle(
                    Object.assign({}, props.subtitles.styles)
                );
            } else if (fileExtension === "smi") {
                setCustomStyle(
                    Object.assign(
                        {},
                        props.subtitles.styles[htmlClass],
                        props.subtitles.styles[id],
                        props.subtitles.styles[tag]
                    )
                );
            } else if (fileExtension === "ssa") {
                if (
                    props.subtitles.styles[ssaStyle]
                ) {
                    if (
                        props.subtitles.styles[ssaStyle].textShadow
                    ) {
                        let textShadow = defaultStyle.textShadow.replace(
                            /rgba\(.*?\)/gi,
                            props.subtitles.styles[ssaStyle].textShadow
                        );
                        let filterShadow = defaultStyle.filter.replace(
                            /rgba\(.*?\)/gi,
                            props.subtitles.styles[ssaStyle].textShadow
                        );
                        setCustomStyle(
                            Object.assign(
                                {},
                                props.subtitles.styles[ssaStyle],
                                { textShadow }, {filter: filterShadow}
                            )
                        );
                    }
                    if(props.subtitles.styles[ssaStyle].fontSize){
                        setFontSize(((props.subtitles.styles[ssaStyle].fontSize * hlsHeight) / playY).toFixed(2) + "px")
                    }
                } else {
                    setCustomStyle(
                        Object.assign({}, props.subtitles.styles[ssaStyle])
                    );
                }
            } else if (fileExtension === "ass") {
                if (
                    props.subtitles.styles[assStyle]
                ) {
                    if (
                        props.subtitles.styles[assStyle].textShadow
                    ) {
                        let textShadow = defaultStyle.textShadow.replace(
                            /rgba\(.*?\)/gi,
                            props.subtitles.styles[assStyle].textShadow
                        );
                        let filterShadow = defaultStyle.filter.replace(
                            /rgba\(.*?\)/gi,
                            props.subtitles.styles[assStyle].textShadow
                        );
                        setCustomStyle(
                            Object.assign(
                                {},
                                props.subtitles.styles[assStyle],
                                { textShadow }, {filter: filterShadow}
                            )
                        );
                    }
                    if(props.subtitles.styles[assStyle].fontSize){
                        setFontSize(((props.subtitles.styles[assStyle].fontSize * hlsHeight) / playY).toFixed(2) + "px")
                    }
                } else {
                    setCustomStyle(
                        Object.assign({}, props.subtitles.styles[assStyle],
                        )
                    );
                }
            } else {
                setCustomStyle({});
            }
        }
        return () => {setCustomStyle({})};
    }, [props.subtitles, fileExtension, htmlClass, id, tag, ssaStyle, assStyle]);

    function calculateFontSize(fontSize){
        if(fontSize === 0) return {};
        return {fontSize: ((fontSize * hlsHeight) / playY).toFixed(2) + "px"}
    }

    function arrayEqual(a, b){
        if(a === null || b === null) return false;
        if(a.length !== b.length) return false;
        for(let i = 0; i < a.length ; i++){
            if(a[i] !== b[i]) return false;
        }
        return true;
    }

    function compare(a, b) {
        return a.index - b.index;
    }

    function binarySearch(subtitles, first, last, currentTime) {
        if (first > last) return -1;
        let mid = Math.floor((first + last) / 2);
        let startTime = subtitles[mid].startTime;
        let endTime = subtitles[mid].endTime;

        if (
            (currentTime >= startTime && currentTime <= endTime) ||
            (fileExtension === "smi" && endTime === undefined)
        ) {
            return mid;
        } else if (currentTime < startTime) {
            return binarySearch(subtitles, first, mid - 1, currentTime);
        } else if (currentTime > endTime) {
            return binarySearch(subtitles, mid + 1, last, currentTime);
        }
    }

    function findAllOccurrences(currentTime, subtitles) {
        let subtitle = new Set();
        let temp;
        let ind = binarySearch(subtitles, 0, subtitles.length - 1, currentTime);
        if (ind === -1) {
            // return [];
            ind = subtitles.length-1;
        } else {
            subtitle.add(subtitles[ind]);
        }

        let left = ind;

        while (left >= 0) {
            temp = binarySearch(subtitles, 0, left, currentTime);
            if (temp >= 0) {
                subtitle.add(subtitles[temp]);
            }
            left -= 1;
        }

        left = 0;
        while (left <= ind && fileExtension !== 'srt') {
            temp = binarySearch(subtitles, left, ind, currentTime);
            if (temp >= 0) {
                subtitle.add(subtitles[temp]);
            }
            left += 1;
        }

        let right = ind;
        while (right <= subtitles.length - 1 && fileExtension !== 'srt') {
            temp = binarySearch(subtitles, right, subtitles.length - 1, currentTime);
            if (
                temp >= 0
            ) {
                subtitle.add(
                    subtitles[
                        temp
                        ]
                );
            }
            right += 1;
        }

        right = subtitles.length - 1;
        while (ind <= right && fileExtension !== 'srt') {
            temp = binarySearch(subtitles, ind, right, currentTime);
            if (
                temp >= 0
            ) {
                subtitle.add(
                    subtitles[
                        temp
                        ]
                );
            }
            right -= 1;
        }
        subtitle = [...subtitle];
        subtitle = subtitle.sort(compare);

        return subtitle;
    }

    function calculatePosition(x, y, playX, playY) {
        let positionX, positionY;

        if (playX !== 0 && playY !== 0) {
            positionX = Math.round((hlsWidth / playX) * x);
            positionY = Math.round(hlsHeight - (hlsHeight / playY) * y);
        } else {
            positionX = null;
            positionY = null;
        }

        return [positionX, positionY];
    }

    function calculateDuration(startTime, endTime) {
        return endTime - startTime;
    }

    function move(startX, startY, endX, endY, startMove, endMove, moveDuration, moveAnimationName) {
        let styleSheet = document.styleSheets[0];
        let destinationX = Math.round(endX - startX);
        let destinationY = Math.round(startY - endY);
        let keyframes = '';
        if(startMove === 0 && endMove === 0){
            keyframes = `@keyframes ${moveAnimationName}{
                0% {transform: translate(0px)}
                100% {transform: translate(${destinationX}px, ${destinationY}px)}
            }`;
        } else {
            keyframes = `@keyframes ${moveAnimationName}{
                0% {transform: translate(0px)}
                ${Math.round(100*(endMove - startMove)/(moveDuration*1000))}%,100% {transform: translate(${destinationX}px, ${destinationY}px)}
            }`;
        }
        if(styleSheet.rules.length > 50 + numOfInitStyles){
            for(let i = numOfInitStyles; i < styleSheet.rules.length; i++){
                if(styleSheet.rules[i].type === 7){
                    styleSheet.deleteRule(i);
                }
            }
        }
        for(let i = styleSheet.cssRules.length - 1; i >= 0; i--){
            if(arrayEqual(styleSheet.rules[i].cssText.match(/[\-\d.]+(?=px)/g), keyframes.match(/[\-\d.]+(?=px)/g))){
                return styleSheet.rules[i].name;
            } else if (i === 0 && !arrayEqual(styleSheet.rules[i].cssText.match(/[\-\d.]+(?=px)/g), keyframes.match(/[\-\d.]+(?=px)/g))) {
                styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
            }
        }
        return moveAnimationName;
    }

    function fadeInOut(fadeType, startFade, endFade, fadeDuration){
        let styleSheet = document.styleSheets[0];
        let keyframes = "";
        let fadeName = `${fadeType}${Math.round(Math.random() * 1000)}`;

        switch(fadeType){
            case "fadein":
                keyframes = `@keyframes ${fadeName}{
                              0%{opacity: 0}
                              100%{opacity: 1;}
                              }`;
                break;
            case "fadeout":
                keyframes = `@keyframes ${fadeName}{
                              0%{opacity: 1}
                              100%{opacity: 0;}
                              }`;
                break;
            case "fadeinout":
                keyframes = `@keyframes ${fadeName}{
                                0%, 100%{opacity: 0}
                                ${Math.round((startFade/fadeDuration)*100)}%, ${Math.round((1-endFade/fadeDuration)*100)}% {opacity: 1}
                            }`;
                break;
            default:
                break;
        }
        if(styleSheet.rules.length > 50 + numOfInitStyles){
            for(let i = numOfInitStyles; i < styleSheet.rules.length; i++){
                if(styleSheet.rules[i].type === 7){
                    styleSheet.deleteRule(i);
                }
            }
        }
        for(let i = styleSheet.cssRules.length - 1; i >= 0; i--){
            if(arrayEqual(styleSheet.rules[i].cssText.replace(styleSheet.rules[i].name,'').match(/[\d]+/g),keyframes.replace(fadeName, '').match(/[\d]+/g))){
                return styleSheet.rules[i].name;
            } else if (i === 0 && styleSheet.rules[i].name !== fadeType) {
                styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
            }
        }
        return fadeName;
    }

    function applyTextShadow(styleValue){
        textShadowColor = defaultStyle.textShadow.replace(
            /rgb\(.*?\)/gi,
            styleValue
        );
        filterShadowColor = defaultStyle.filter.replace(
            /rgb\(.*?\)/gi,
            styleValue
        );
    }

    function updateTextShadow(strokeSize, strokeColor, direction) {
        let textShadow = '';
        for (let angle=0; angle<2*Math.PI; angle+=1/strokeSize) {
            if(direction === "all")
                textShadow += appendShadow(Math.cos(angle) * strokeSize, Math.sin(angle) * strokeSize, strokeColor);
            else if(direction === "x")
                textShadow += appendShadow(Math.cos(angle) * strokeSize, 0, strokeColor);
            else if(direction === "y")
                textShadow += appendShadow(0, Math.sin(angle) * strokeSize, strokeColor);
        }
        if(direction === "x" || direction === "y"){
            textShadow = defaultStyle.textShadow + textShadow.substring(0, textShadow.length - 2);
        }
        else{
            textShadow = textShadow.substring(0, textShadow.length - 2);
            defaultStyle.textShadow = textShadow;
        }
        return textShadow;
    }

    function appendShadow(x, y, color){
        let textShadow = '';
        textShadow = textShadow + color + ' ' + x + 'px ' + y + 'px ' + ', ';
        return textShadow;
    }

    function getOverrideStyle(type, styleKey, styleValue, tempStyle, modifierObj, element, hasPos) {
        switch (styleKey) {
            case "fs":
                styleKey = type === "other" ? "fontSize" : "font-size";
                if (styleValue) {
                    styleValue = ((styleValue * hlsHeight) / playY).toFixed(2) + "px";
                } else {
                    styleValue = defaultStyle.fontSize;
                }
                break;
            case "fsp":
                styleKey = type === "other" ? "letterSpacing" : "letter-spacing";
                styleValue += "px";
                break;
            case "c&H":
            case "1c&H":
                styleKey = "color";
                styleValue = styleValue
                    .replace("1c&H", "")
                    .replace("c&H", "")
                    .replace("&", "");
                styleValue =
                    "#" +
                    styleValue[4] +
                    styleValue[5] +
                    styleValue[2] +
                    styleValue[3] +
                    styleValue[0] +
                    styleValue[1];
                break;
            case "2c&H":
                styleKey = "2c&H";
                styleValue = styleValue.replace("2c&H", "").replace("&", "");
                styleValue =
                    "#" +
                    styleValue[4] +
                    styleValue[5] +
                    styleValue[2] +
                    styleValue[3] +
                    styleValue[0] +
                    styleValue[1];
                break;
            case "3c&H":
                styleKey = type === "other" ? "borderColor" : "border-color";
                styleValue = styleValue.replace("3c&H", "").replace("&", "");
                styleValue =
                    "#" +
                    styleValue[4] +
                    styleValue[5] +
                    styleValue[2] +
                    styleValue[3] +
                    styleValue[0] +
                    styleValue[1];
                break;
            case "4c&H":
                styleKey = type === "other" ? "textShadow" : "text-shadow";
                styleValue = styleValue.replace("4c&H", "").replace("&", "");
                styleValue =
                    "#" +
                    styleValue[4] +
                    styleValue[5] +
                    styleValue[2] +
                    styleValue[3] +
                    styleValue[0] +
                    styleValue[1];
                applyTextShadow(styleValue);
                break;
            case "alpha&H":
                styleKey = "opacity";
                styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)) / 255;
                break;
            case "1a&H":
                styleKey = "color";
                if (type === "other") {
                    styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                        16
                    );
                    if (styleValue.length === 1) styleValue = "0" + styleValue;
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + styleValue
                        : `#000000${styleValue}`;
                } else if (type === "t") {
                    styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                        16
                    );
                    if (styleValue.length === 1) styleValue = "0" + styleValue;
                    if (modifierObj[styleKey])
                        styleValue = modifierObj[styleKey] + styleValue;
                    else if (tempStyle[styleKey]) {
                        if (tempStyle[styleKey].length === 9)
                            styleValue = tempStyle[styleKey].substring(0, 7) + styleValue;
                        else styleValue = tempStyle[styleKey] + styleValue;
                    } else styleValue = `#000000${styleValue}`;
                }
                break;
            case "2a&H":
                styleKey = "2a&H";
                styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                    16
                );
                if (styleValue.length === 1) styleValue = "0" + styleValue;
                break;
            case "3a&H":
                if (type === "other") {
                    styleKey = "borderColor";
                    styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                        16
                    );
                    if (styleValue.length === 1) styleValue = "0" + styleValue;
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + styleValue
                        : `#000000${styleValue}`;
                } else if (type === "t") {
                    styleKey = "border-color";
                    styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                        16
                    );
                    if (styleValue.length === 1) styleValue = "0" + styleValue;
                    if (modifierObj[styleKey])
                        styleValue = modifierObj[styleKey] + styleValue;
                    else if (tempStyle[styleKey])
                        styleValue = tempStyle[styleKey] + styleValue;
                    else styleValue = `#000000${styleValue}`;
                }
                break;
            case "4a&H":
                if (type === "other") {
                    styleKey = "textShadow";
                    styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                        16
                    );
                    if (styleValue.length === 1) styleValue = "0" + styleValue;
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + styleValue
                        : `#000000${styleValue}`;
                } else if (type === "t") {
                    styleKey = "text-shadow";
                    styleValue = (255 - parseInt(styleValue.replace("&", ""), 16)).toString(
                        16
                    );
                    if (styleValue.length === 1) styleValue = "0" + styleValue;
                    if (modifierObj[styleKey])
                        styleValue = modifierObj[styleKey] + styleValue;
                    else if (tempStyle[styleKey])
                        styleValue = tempStyle[styleKey] + styleValue;
                    else styleValue = `#000000${styleValue}`;
                }
                applyTextShadow(styleValue);
                break;
            case "fscx":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue =
                            tempStyle[styleKey] + ` scaleX(${parseInt(styleValue, 10) / 100})`;
                    else styleValue = `scaleX(${parseInt(styleValue, 10) / 100})`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue =
                            modifierObj[styleKey] +
                            ` scaleX(${parseInt(styleValue, 10) / 100})`;
                    else styleValue = `scaleX(${parseInt(styleValue, 10) / 100})`;
                }
                break;
            case "fscy":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue =
                            tempStyle[styleKey] + ` scaleY(${parseInt(styleValue, 10) / 100})`;
                    else styleValue = `scaleY(${parseInt(styleValue, 10) / 100})`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue =
                            modifierObj[styleKey] +
                            ` scaleY(${parseInt(styleValue, 10) / 100})`;
                    else styleValue = `scaleY(${parseInt(styleValue, 10) / 100})`;
                }
                break;
            case "frx":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue =
                            tempStyle[styleKey] + ` rotate3d(1, 0, 0, ${-styleValue}deg)`;
                    else styleValue = `rotate3d(1, 0, 0, ${-styleValue}deg)`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue =
                            modifierObj[styleKey] + ` rotate3d(1, 0, 0, ${-styleValue}deg)`;
                    else styleValue = `rotate3d(1, 0, 0, ${-styleValue}deg)`;
                }
                break;
            case "fry":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue =
                            tempStyle[styleKey] + ` rotate3d(0, 1, 0, ${-styleValue}deg)`;
                    else styleValue = `rotate3d(0, 1, 0, ${-styleValue}deg)`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue =
                            modifierObj[styleKey] + ` rotate3d(0, 1, 0, ${-styleValue}deg)`;
                    else styleValue = `rotate3d(0, 1, 0, ${-styleValue}deg)`;
                }
                break;
            case "frz":
            case "fr":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue =
                            tempStyle[styleKey] + ` rotate3d(0, 0, 1, ${-styleValue}deg)`;
                    else styleValue = `rotate3d(0, 0, 1, ${-styleValue}deg)`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue =
                            modifierObj[styleKey] + ` rotate3d(0, 0, 1, ${-styleValue}deg)`;
                    else styleValue = `rotate3d(0, 0, 1, ${-styleValue}deg)`;
                }
                break;
            case "fax":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue = tempStyle[styleKey] + ` skew(${90 * styleValue}deg)`;
                    else styleValue = `skew(${90 * styleValue}deg)`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue = modifierObj[styleKey] + ` skew(${90 * styleValue}deg)`;
                    else styleValue = `skew(${90 * styleValue}deg)`;
                }
                break;
            case "fay":
                styleKey = "transform";
                if (type === "other") {
                    if (tempStyle[styleKey])
                        styleValue = tempStyle[styleKey] + ` skew(0, ${90 * styleValue}deg)`;
                    else styleValue = `skew(0, ${90 * styleValue}deg)`;
                } else if (type === "t") {
                    if (modifierObj[styleKey])
                        styleValue =
                            modifierObj[styleKey] + ` skew(0, ${90 * styleValue}deg)`;
                    else styleValue = `skew(0, ${90 * styleValue}deg)`;
                }
                break;
            case "bord":
                styleKey = type === "other" ? "textShadow" : "text-shadow";
                styleValue = updateTextShadow(styleValue, "rgb(0,0,0)", "all");
                break;
            case "shad":
                styleKey = type === "other" ? "textShadow" : "text-shadow";
                styleValue = `${styleValue}px ${styleValue}px`;
                break;
            case "xbord":
            case "xshad":
                styleKey = type === "other" ? "textShadow" : "text-shadow";
                styleValue = updateTextShadow(styleValue, "rgb(0,0,0)", "x");
                break;
            case "ybord":
            case "yshad":
                styleKey = type === "other" ? "textShadow" : "text-shadow";
                styleValue = updateTextShadow(styleValue, "rgb(0,0,0)", "y");
                break;
            case "be":
            case "blur":
                if (type === "other") {
                    styleKey = "textShadow";
                    if (defaultStyle.textShadow)
                        styleValue = defaultStyle.textShadow
                            .split(/,(?![^(]*\))/gi)
                            .map(i => i + " " + styleValue + "px")
                            .join(",");
                    else if (tempStyle.textShadow)
                        styleValue = tempStyle.textShadow
                            .split(/,(?![^(]*\))/gi)
                            .map(i => i + " " + styleValue + "px")
                            .join(",");
                    else styleValue = `blur(${styleValue}px)`;
                } else if (type === "t") {
                    styleKey = "text-shadow";
                    if (defaultStyle.textShadow)
                        styleValue = defaultStyle.textShadow
                            .split(/,(?![^(]*\))/gi)
                            .map(i => i + " " + styleValue + "px")
                            .join("$");
                    else if (tempStyle.textShadow)
                        styleValue = tempStyle.textShadow
                            .split(/,(?![^(]*\))/gi)
                            .map(i => i + " " + styleValue + "px")
                            .join("$");
                    else styleValue = `blur(${styleValue}px)`;
                }
                break;
            case "an1":
            case "a1":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(5%,0%)"
                        : "translate(5%,0%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        0,
                        playY,
                        playX,
                        playY
                    );
                }
                break;
            case "an2":
            case "a2":
                break;
            case "an3":
            case "a3":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(-15%,0%)"
                        : "translate(-15%,0%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        playX,
                        playY,
                        playX,
                        playY
                    );
                }
                break;
            case "an4":
            case "a9":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(5%,25%)"
                        : "translate(5%,25%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        0,
                        playY/2,
                        playX,
                        playY
                    );
                }
                break;
            case "an5":
            case "a5":
            case "a10":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(0%,25%)"
                        : "translate(0%,25%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        playX/2,
                        playY/2,
                        playX,
                        playY
                    );
                }
                break;
            case "an6":
            case "a8":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(-15%,25%)"
                        : "translate(-15%,25%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        playX,
                        playY/2,
                        playX,
                        playY
                    );
                }
                break;
            case "an7":
            case "a4":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(5%,50%)"
                        : "translate(5%,50%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        0,
                        0,
                        playX,
                        playY
                    );
                }
                break;
            case "an8":
            case "a6":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(0%,50%)"
                        : "translate(0%,50%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        playX/2,
                        0,
                        playX,
                        playY
                    );
                }
                break;
            case "an9":
            case "a7":
                if(hasPos && element.text.match(/(an|a)\d/gi)[0] === styleKey){
                    styleKey = "transform";
                    styleValue = tempStyle[styleKey]
                        ? tempStyle[styleKey] + " translate(-15%,50%)"
                        : "translate(-15%,50%)";
                } else {
                    [positionX, positionY] = calculatePosition(
                        playX,
                        0,
                        playX,
                        playY
                    );
                }

                break;
            case "b":
                styleKey = "fontWeight";
                if (styleValue === "1") styleValue = "bold";
                else styleValue = "normal";
                break;
            case "i":
                styleKey = "fontStyle";
                if (styleValue === "1") styleValue = "italic";
                else styleValue = "normal";
                break;
            case "u":
                styleKey = "textDecoration";
                if (styleValue === "1") styleValue = "underline";
                else styleValue = "none";
                break;
            case "s":
                styleKey = "textDecoration";
                if (styleValue === "1") styleValue = "line-through";
                else styleValue = "none";
                break;
            case "fn":
                styleKey = "fontFamily";
                break;
            case "pos":
                styleValue = styleValue.match(/[\d.]+/g);
                [positionX, positionY] = calculatePosition(
                    styleValue[0],
                    styleValue[1],
                    playX,
                    playY
                );
                break;
            case "move":
                let endX, endY, startMove, endMove;
                let coordinates = styleValue.match(/[\-\d.]+/g);
                let moveAnimationName = `move${Math.round(Math.random() * 1000)}`;
                [positionX, positionY] = calculatePosition(
                    parseFloat(coordinates[0], 100),
                    parseFloat(coordinates[1], 100),
                    playX,
                    playY
                );
                [endX, endY] = calculatePosition(
                    parseFloat(coordinates[2], 100),
                    parseFloat(coordinates[3], 100),
                    playX,
                    playY
                );

                if (coordinates.length === 4) {
                    moveDuration = calculateDuration(element.startTime, element.endTime);
                    moveDelay = 0;
                    startMove = 0;
                    endMove = 0;
                } else if (styleValue.match(/[\d.]+/g).length === 6) {
                    [startMove, endMove] = [
                        parseFloat(coordinates[4], 100),
                        parseFloat(coordinates[5], 100)
                    ];
                    moveDuration =
                        calculateDuration(element.startTime, element.endTime) -
                        startMove / 1000;
                    moveDelay = startMove;
                }
                moveAnimationName = move(
                    positionX,
                    positionY,
                    endX,
                    endY,
                    startMove,
                    endMove,
                    moveDuration,
                    moveAnimationName
                );
                styleKey = "animationName";
                styleValue = moveAnimationName;
                break;
            case "fad":
                let startFade = parseInt(styleValue.match(/[\-\d.]+/g)[0], 10);
                let endFade = parseInt(styleValue.match(/[\-\d.]+/g)[1], 10);
                fadeDuration = parseInt(
                    calculateDuration(element.startTime, element.endTime) * 1000,
                    10
                );
                if (startFade === 0 && endFade === 0) {
                    fadeDuration = 0;
                    break;
                } else if (startFade === 0) {
                    fadeType = "fadeout";
                    fadeDelay = fadeDuration - endFade;
                    fadeDuration = endFade;
                } else if (endFade === 0) {
                    fadeType = "fadein";
                    fadeDuration = startFade;
                    fadeDelay = 0;
                } else {
                    fadeType = "fadeinout";
                    fadeDelay = 0;
                }
                styleKey = "fadeName";
                styleValue = fadeInOut(fadeType, startFade, endFade, fadeDuration);
                break;
            case "t":
                let tStart, tEnd, styleModifiers;
                if (styleValue.match(/\$clip/gi)) {
                    styleValue = styleValue.replace(/\$clip\([,\d\w\s]+\)/gi, "");
                } else if (styleValue.match(/\$iclip/gi)) {
                    styleValue = styleValue.replace(/\$iclip\([,\d\w\s]+\)/gi, "");
                } else if (styleValue.match(/\$1vc/gi)) {
                    styleValue = styleValue.replace(/\$1vc\([,\d\w\s]+\)/gi, "");
                }
                styleValue = styleValue
                    .replace(/\(/g, "")
                    .replace(/\)/g, "")
                    .split(",");
                if (styleValue.length === 1) {
                    tStart = 0;
                    tEnd = 0;
                    tAccel = 1;
                    styleModifiers = styleValue[0];
                } else if (styleValue.length === 2) {
                    [tAccel, styleModifiers] = styleValue;
                } else if (styleValue.length === 3) {
                    [tStart, tEnd, styleModifiers] = styleValue;
                    tAccel = 1;
                } else if (styleValue.length === 4) {
                    [tStart, tEnd, tAccel, styleModifiers] = styleValue;
                }
                tStart = parseInt(tStart, 10);
                tEnd = parseInt(tEnd, 10);
                styleModifiers = styleModifiers.replace(/\)/gi, "").split("$");
                if (tStart === 0 && tEnd === 0) {
                    tDelay = 0;
                    tDuration = calculateDuration(element.startTime, element.endTime);
                } else {
                    tDelay = tStart;
                    tDuration = tEnd - tStart;
                }
                if (styleModifiers.length === 0 || styleModifiers === "") break;
                styleKey = "t";
                styleValue = tempStyle[styleKey]
                    ? tempStyle[styleKey] +
                    ";" +
                    `(${animatedTransform(
                        styleModifiers,
                        tempStyle
                    )},${tDuration},${tDelay},${tAccel})`
                    : `(${animatedTransform(
                        styleModifiers,
                        tempStyle
                    )},${tDuration},${tDelay},${tAccel})`;
                break;
            case "r":
                styleKey = "reset";
                styleValue = true;
                break;
            case "k":
            case "K":
            case "kf":
            case "ko":
                styleKey = 'karaoke';
                styleValue = styleValue;
                break;
            case "fade":
                break;
            default:
                styleKey = "";
                styleValue = "";
                break;
        }
        return [styleKey,styleValue];
    }

    function hexToRgbA(hex){
        let c,a;
        c= '0x' + hex.substring(1).toLowerCase();
        if(c.endsWith('ff')){
            if(c.length === 10)
                c = c.substring(0,8);
            return 'rgb('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+')';
        }
        else{
            if(c.length === 10){
                a = ((c&255)/255).toFixed(2);
                a = a[a.length-1] === "0" ? a.substring(0,a.length-1) : a;
                return 'rgba('+[(c>>24)&255, (c>>16)&255, (c>>8)&255, a].join(',')+')';
            }
            else
                return 'rgb('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+')';
        }
    }

    function animatedTransform(styleModifiers, tempStyle){
        let keyframes = '', modifierKey = '', modifierValue = '', modifierObj = {};
        let tName = `t${Math.round(Math.random() * 1000)}`;
        let styleSheet = document.styleSheets[0];
        styleModifiers.forEach(styleModifier => {
            if(styleModifier !== "" && isNaN(styleModifier)){
                if (styleModifier.match(/c&h/i)) {
                    modifierKey = styleModifier.match(/\d*c&h/i)[0];
                } else if (styleModifier.match(/a&h/i)){
                    modifierKey = styleModifier.match(/.*a&h/i)[0];
                } else if (styleModifier.match(/an\d+/i)){
                    modifierKey = styleModifier;
                } else {
                    modifierKey = styleModifier.match(/[a-z]+/i)[0];
                }
                modifierValue = styleModifier.replace(modifierKey,"");
                [modifierKey, modifierValue] = getOverrideStyle('t', modifierKey, modifierValue, tempStyle, modifierObj,{}, false);
                modifierObj[modifierKey] = modifierValue;
            }
        });

        if(modifierObj['color']){
            modifierObj['color'] = hexToRgbA(modifierObj.color);
        }
        modifierObj = JSON.stringify(modifierObj).replace(/"/gi,'').replace(/\((.*?)\)/g, match => {
            return match.replace(/,/g, "$");
        }).replace(/,/gi,';').replace(/\$/gi,',');

        keyframes = `@keyframes ${tName}{
                              100%${modifierObj}`;
        if(styleSheet.rules.length > 50 + numOfInitStyles){
            for(let i = numOfInitStyles; i < styleSheet.rules.length; i++){
                if(styleSheet.rules[i].type === 7){
                    styleSheet.deleteRule(i);
                }
            }
        }
        for(let i = styleSheet.cssRules.length - 1; i >= 0; i--){
            if(styleSheet.rules[i].cssRules !== undefined && modifierObj !== "{}"){
                if(arrayEqual(
                    styleSheet.rules[i].cssRules[0].cssText.replace('100%','').match(/[-.\d]+/g).sort(),
                    JSON.stringify(modifierObj).match(/[-.\d]+/g).sort())
                ){
                    return styleSheet.rules[i].name;
                }
            }
            if (i === 0 && styleSheet.rules[i].name !== tName && modifierObj !== "{}") {
                styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
                return tName;
            }
        }
    }

    function getCanvasWidth(width){
        setCanvasWidth(width);
    }

    function renderOverrideSubtitle() {
        let fragment = "";
        let tag = "";
        let tempTag = "";
        let result = "";
        let done = false;
        let style = {};
        let tempStr = "";
        let tempArr = [];
        let renderStyle = {};
        let hasPos = false;

        return overrideTextArray.map(element => {
            if (element !== undefined && !element.text.match(/{.*p1(?=.*})/gi)) {
                if (element.text.match(/{(.*)}/gi)) {
                    tempArr = [];
                    hasPos = element.text.match(/(pos|move)\(/gi);
                    tempStr = element.text
                        .replace(/\((.*?)\)/g, match => {
                            return match.replace(/\\/g, "$");
                        })
                        .split("{");
                    tempStr.forEach(i => {
                        if (i !== "") {
                            i = i.split("}");
                            let tempObj = {};
                            i.forEach(j => {
                                if (j.match(/\\/g)) {
                                    let tempStyle = {};
                                    j = j.split(/\\/g);
                                    j.forEach(k => {
                                        if (k !== "") {
                                            let styleKey = k;
                                            if (styleKey.match(/\((.*)\)/)) {
                                                styleKey = styleKey.match(/\w+(?=\()/i)[0];
                                            } else if (styleKey.match(/c&h/i)) {
                                                styleKey = styleKey.match(/\d*c&h/i)[0];
                                            } else if (styleKey.match(/a&h/i)) {
                                                styleKey = styleKey.match(/.*a&h/i)[0];
                                            } else if (styleKey.match(/an\d+/i) || styleKey.match(/a\d+/i)) {
                                                styleKey = k;
                                            } else {
                                                styleKey = k.match(/[a-z]+/i) ? k.match(/[a-z]+/i)[0] : 0;
                                            }
                                            let styleValue = k.replace(styleKey, "");

                                            [styleKey, styleValue] = getOverrideStyle('other', styleKey, styleValue, tempStyle, {}, element, hasPos);
                                            tempStyle[styleKey] = styleValue;
                                        }
                                    });
                                    if (tempArr[tempArr.length - 1]) {
                                        let tempT = {};
                                        if(tempArr[tempArr.length - 1].style){
                                            if(tempArr[tempArr.length - 1].style.t && tempStyle.t){
                                                if(tempArr[tempArr.length - 1].style.t.match(tempStyle.t))
                                                    tempT = {t: `${tempArr[tempArr.length - 1].style.t}`};
                                                else
                                                    tempT = {t: `${tempArr[tempArr.length - 1].style.t + ';' + tempStyle.t}`};
                                            }
                                        }
                                        style = Object.assign(
                                            {},
                                            tempArr[tempArr.length - 1].style,
                                            tempStyle,
                                            tempArr[tempArr.length - 1].style ?
                                                (tempArr[tempArr.length - 1].style.transform && tempStyle.transform ?
                                                    {transform: `${tempArr[tempArr.length - 1].style.transform + tempStyle.transform}`}
                                                    : {})
                                                : {},
                                            tempT
                                        );
                                    } else {
                                        style = tempStyle;
                                    }
                                    tempObj.style = style;
                                } else {
                                    tempObj.text = j;
                                }
                            });
                            tempArr.push(tempObj);
                        }
                    });
                    let position = {};
                    let shadow = {};
                    let moveAnimation = {};
                    let fadeAnimation = {};
                    let tAnimation = {};
                    if (positionX !== null && positionY !== null) {
                        if(positionY < 52) positionY += 52;
                        else if(positionY > hlsHeight) positionY = hlsHeight;
                        position = {
                            position: "fixed",
                            bottom: `${positionY}px`,
                            left: `${positionX}px`,
                            transform: positionY >= hlsHeight ? 'translate(-50%, 100%)' : 'translate(-50%, 50%)'
                        };
                        positionX = null;
                        positionY = null;
                    }
                    if (textShadowColor !== "" || filterShadowColor !== "") {
                        shadow = {
                            textShadow: `${textShadowColor}`,
                            filter: `${filterShadowColor}`
                        };
                        textShadowColor = "";
                        filterShadowColor = "";
                    }
                    if(tempArr[0].style){
                        let playbackRate = 1;
                        let elementStartTime = element.startTime;
                        let elementEndTime = element.endTime;
                        if(subtitleSeekTime > elementStartTime && subtitleSeekTime < elementEndTime && subtitleSeekTime >= 0){
                            moveDelay = (elementStartTime - currentTime) * 1000;
                            fadeDelay = (elementStartTime - currentTime) * 1000;
                            tDelay = (elementStartTime - currentTime) * 1000;
                        }
                        if (tempArr[0].style['animationName']) {
                            moveAnimation = {
                                animationName: `${tempArr[0].style['animationName']}`,
                                animationTimingFunction: "linear",
                                animationDuration: `${Math.round(((moveDuration * 1000)) / playbackRate)}ms`,
                                animationIterationCount: '1',
                                animationDelay: `${moveDelay / playbackRate}ms`,
                                animationFillMode: 'forwards',
                                animationPlayState: `${props.playing ? "running" : "paused"}`
                            };
                            moveDuration = 0;
                            moveDelay = 0;
                        }
                        if (fadeDuration !== 0 && tempArr[0].style['fadeName']) {
                            fadeAnimation = {
                                animationName: `${tempArr[0].style['fadeName']}`,
                                animationTimingFunction: "linear",
                                animationDuration: `${fadeDuration / playbackRate}ms`,
                                animationIterationCount: '1',
                                animationDelay: `${fadeDelay / playbackRate}ms`,
                                animationFillMode: 'forwards',
                                animationPlayState: `${props.playing ? "running" : "paused"}`
                            };
                            fadeType = "";
                            fadeDuration = 0;
                        }
                        if (tDuration !== 0 && tempArr[0].style['t']) {
                            let tString, tDuration, tDelay, tAccel, tName;
                            tempArr[0].style['t'].split(';').forEach(element => {
                                [tName, tDuration, tDelay, tAccel] = element.replace(/\(/gi, '').replace(/\)/gi, '').split(',');
                                if (tAccel === '1') tAccel = 'linear';
                                else if (tAccel > 0 && tAccel < 1) tAccel = 'ease-out';
                                else if (tAccel > 1) tAccel = 'ease-in';
                                if (tName !== "undefined") {
                                    tString = `${tDuration / playbackRate}ms ${tAccel} ${tDelay / playbackRate}ms 1 forwards ${props.playing ? "running" : "paused"} ${tName}`;
                                    tAnimation['animation'] = tAnimation['animation'] ? tAnimation['animation'] + ',' + tString : tString;
                                }
                            });
                        }
                    }
                    let x = 0;
                    while(x < tempArr.length - 1){
                        if((JSON.stringify(tempArr[x].style) === JSON.stringify(tempArr[x+1].style))){
                            tempArr[x].text += tempArr[x+1].text;
                            tempArr.splice(x+1, 1);
                        } else {
                            x += 1;
                        }
                    }

                    return (
                        <div key={element.text}
                             style={moveAnimation}>
                            <div key={element.text}
                                 style={Object.assign({},
                                     {width: tempArr[0].style ? tempArr[0].style['karaoke'] ? canvasWidth : '100%' : '100%'},
                                     position,
                                     fadeAnimation,
                                     (JSON.stringify(moveAnimation) === "{}" && JSON.stringify(tAnimation) === "{}" && JSON.stringify(position) === "{}") ? {position:'absolute', bottom: "0%", marginBottom: '20px'}: {},
                                     (JSON.stringify(moveAnimation) === "{}" && JSON.stringify(tAnimation) === "{}" && JSON.stringify(position) === "{}") ? props.barStatus && (hlsHeight > 500 && window.innerHeight - hlsHeight < 116) ? {marginBottom: "150px"} : {} : {}
                                     )
                                 }>
                                {tempArr.map(o => {
                                    if (o.text !== "" && o.text !== undefined) {
                                        return o.text.split(/ /).map((text, index) => {
                                            if (text.match(/<\w+>/gi)) {
                                                tempTag = text
                                                    .match(/(?:<)(.*?)(?=>)/)[0]
                                                    .replace("<", "");

                                                if (tempTag === "br" || fragment !== "") {
                                                    done = true;
                                                }
                                            } else if (
                                                text.match(/<\/\w+>/gi) ||
                                                text.match(/{\\\w+0}/gi)
                                            ) {
                                                done = true;
                                            } else {
                                                fragment += text + " ";
                                            }
                                            if (done || index === o.text.split(/ /).length - 1) {
                                                done = false;
                                                result = fragment;
                                                switch (tag) {
                                                    case "i":
                                                    case "i1":
                                                        style = {fontStyle: "italic"};
                                                        break;
                                                    case "u":
                                                    case "u1":
                                                        style = {textDecoration: "underline"};
                                                        break;
                                                    case "b":
                                                    case "b1":
                                                        style = {fontWeight: "bold"};
                                                        break;
                                                    case "br":
                                                        fragment = "";
                                                        tag = tempTag ? tempTag : "";
                                                        tempTag = "";

                                                        if(result.replace(/ /g, '') === "") return "";
                                                        return (
                                                            <div
                                                                key={result}
                                                                style={Object.assign(
                                                                    {},
                                                                    customStyle,
                                                                    props.subtitles.styles ? props.subtitles.styles[element.style] : {},
                                                                    calculateFontSize(props.subtitles.styles ? props.subtitles.styles[element.style].fontSize : 0),
                                                                    {marginLeft: element.marginLeft, marginRight: element.marginRight},
                                                                    o.style,
                                                                    style,
                                                                    shadow,
                                                                    tAnimation,
                                                                    (element.text.match(/<br>/gi)) ? {display: "block"} : {display: "inline-block"},
                                                                    o.style ? (o.style.reset ? {...defaultStyle, fontSize} : {} ) : {}
                                                                )}
                                                            >
                                                                {result}
                                                            </div>
                                                        );
                                                    default:
                                                        style = {};
                                                        break;
                                                }

                                                fragment = "";
                                                tag = tempTag ? tempTag : "";
                                                tempTag = "";

                                                if(result.replace(/ /g, '') === "") return "";
                                                renderStyle = Object.assign(
                                                    {},
                                                    customStyle,
                                                    props.subtitles.styles ? props.subtitles.styles[element.style] : {},
                                                    calculateFontSize(props.subtitles.styles ? props.subtitles.styles[element.style].fontSize : 0),
                                                    {marginLeft: element.marginLeft, marginRight: element.marginRight},
                                                    o.style,
                                                    style,
                                                    shadow,
                                                    tAnimation,
                                                    (element.text.match(/<br>/gi)) ? {display: "block"} : {display: "inline-block"},
                                                    o.style ? (o.style.reset ? {...defaultStyle,fontSize} : {} ) : {}
                                                );
                                                return (
                                                    <div key={result}>
                                                        {tempArr[0].style && tempArr[0].style['karaoke'] ?
                                                            <Canvas text={result} styling={tempArr[0].style} renderStyle={renderStyle} playing={props.playing} getCanvasWidth={getCanvasWidth}/> :
                                                            <div key={result} style={renderStyle}>
                                                                {result}
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            }
                                        });
                                    }
                                })}
                            </div>
                        </div>
                    );
                }
            }
            else return "";
        })
    }

    function renderNormalSubtitle(){
        let fragment = "";
        let tag = "";
        let tempTag = "";
        let result = "";
        let done = false;
        let style = {};

        return normalTextArray.map(element => {
            if (element !== undefined) {
                return element.text.split(/ /).map((text, index) => {
                    text = text.replace(/{(.*)}/gi, "");
                    if (text.match(/<\w+>/gi) || text.match(/{\\\w+1}/gi)) {
                        tempTag = text.match(/<\w+>/gi)
                            ? text.match(/(?:<)(.*?)(?=>)/)[0].replace("<", "")
                            : text.match(/(?:{\\)(.*?)(?=})/)[0].replace("{\\", "");

                        if (tempTag === "br" || fragment !== "") {
                            done = true;
                        }
                    }
                    else if (text.match(/<\/\w+>/gi) || text.match(/{\\\w+0}/gi)) {
                        done = true;
                    }
                    else {
                        fragment += text + " ";
                    }

                    if (done || index === element.text.split(/ /).length - 1) {
                        done = false;
                        result = fragment;
                        switch (tag) {
                            case "i":
                            case "i1":
                                style = { fontStyle: "italic" };
                                break;
                            case "u":
                            case "u1":
                                style = { textDecoration: "underline" };
                                break;
                            case "b":
                            case "b1":
                                style = { fontWeight: "bold" };
                                break;
                            case "br":
                                fragment = "";
                                tag = tempTag ? tempTag : "";
                                tempTag = "";
                                return (
                                    <div key={index} style={Object.assign({}, customStyle, {
                                        ...style,
                                        position: "relative",
                                        bottom: "0%",
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: fileExtension !== "srt" ? fontSize : "",
                                        marginLeft: element.marginLeft,
                                        marginRight: element.marginRight
                                    })}>
                                        {result}
                                    </div>
                                );
                            default:
                                style = {};
                                break;
                        }

                        fragment = "";
                        tag = tempTag ? tempTag : "";
                        tempTag = "";
                        return (
                            <div key={index} style={Object.assign({}, customStyle, {
                                ...style,
                                position: "relative",
                                bottom: "0%",
                                width: "100%",
                                textAlign: "center",
                                fontSize: fileExtension !== "srt" ? fontSize : "",
                                marginLeft: element.marginLeft,
                                marginRight: element.marginRight
                            })}>
                                {result}
                            </div>
                        );
                    }
                });
            }
            else {
                return "";
            }
        });
    }

    function renderSubtitle() {
        if(fileExtension !== ""){
            if (fileExtension === "ass" || fileExtension === "ssa" || fileExtension === "srt") {
                return(
                    <div>
                        <div>
                            {overrideTextArray.length > 0 ? renderOverrideSubtitle() : ""}
                        </div>
                        <div style={Object.assign({},{position: 'fixed', width: '100%', bottom: "0%", marginBottom: '20px'},
                            props.barStatus && (hlsHeight > 500 && window.innerHeight - hlsHeight < 116) ? {marginBottom: "150px"} : {})}>
                            {normalTextArray.length > 0 ? renderNormalSubtitle() : ""}
                        </div>
                    </div>
                )
            }
            else {
                let subtitle = subtitleText;
                if (subtitle !== "" && subtitle !== "&nbsp;") {
                    let fragment = "";
                    let tag = "";
                    let tempTag = "";
                    let result = "";
                    let done = false;
                    let style = {};

                    subtitle = subtitle.split(/ /).map((text, index) => {
                        if (text.match(/<\w+>/gi) || text.match(/{\\\w+1}/gi)) {
                            tempTag = text.match(/<\w+>/gi)
                                ? text.match(/(?:<)(.*?)(?=>)/)[0].replace("<", "")
                                : text.match(/(?:{\\)(.*?)(?=})/)[0].replace("{\\", "");

                            if (tempTag === "br" || fragment !== "") {
                                done = true;
                            }
                        } else if (text.match(/<\/\w+>/gi) || text.match(/{\\\w+0}/gi)) {
                            done = true;
                        } else {
                            fragment += text + " ";
                        }

                        if (done || index === subtitle.split(/ /).length - 1) {
                            done = false;
                            result = fragment;
                            switch (tag) {
                                case "i":
                                case "i1":
                                    style = { fontStyle: "italic" };
                                    break;
                                case "u":
                                case "u1":
                                    style = { textDecoration: "underline" };
                                    break;
                                case "b":
                                case "b1":
                                    style = { fontWeight: "bold" };
                                    break;
                                case "br":
                                    fragment = "";
                                    tag = tempTag ? tempTag : "";
                                    tempTag = "";
                                    return (
                                        <div key={index} style={style}>
                                            {result}
                                        </div>
                                    );
                                default:
                                    style = {};
                                    break;
                            }

                            fragment = "";
                            tag = tempTag ? tempTag : "";
                            tempTag = "";
                            return (
                                <div key={index} style={{ ...style, display: "inline", fontSize }}>
                                    {result}
                                </div>
                            );
                        }
                    });
                    return(
                        <div style={Object.assign({},
                            {position:"absolute", width:"100%", bottom: "0%", marginBottom: "20px", fontSize},
                            props.barStatus && (hlsHeight > 500 && window.innerHeight - hlsHeight < 116) ? {marginBottom: "150px"} : {}
                            )}
                        >
                            {subtitle}
                        </div>
                    )

                }
            }
        }
    }
    return (
        <div
            className="subtitle"
            style={Object.assign({}, defaultStyle)}
        >
            {renderSubtitle()}
        </div>

    );
};
// test commit on 3/6/2020 from home
export default Subtitle;
