import PropTypes from 'prop-types'

const { string, bool, number, array, oneOfType, shape, object, func } = PropTypes

export const propTypes = {
  url: oneOfType([ string, array, object ]),
  playing: bool,
  loop: bool,
  controls: bool,
  volume: number,
  muted: bool,
  playbackRate: number,
  width: oneOfType([ string, number ]),
  height: oneOfType([ string, number ]),
  style: object,
  progressInterval: number,
  playsinline: bool,
  pip: bool,
  resumeTime: number,
  light: oneOfType([ bool, string ]),
  wrapper: oneOfType([
    string,
    func,
    shape({ render: func.isRequired })
  ]),
  config: shape({
    file: shape({
      attributes: object,
      tracks: array,
      forceVideo: bool,
      forceAudio: bool,
      forceHLS: bool,
      forceDASH: bool,
      hlsOptions: object,
      hlsVersion: string,
      dashVersion: string
    })
  }),
  onReady: func,
  onStart: func,
  onPlay: func,
  onPause: func,
  onBuffer: func,
  onBufferEnd: func,
  onEnded: func,
  onError: func,
  onDuration: func,
  onSeek: func,
  onProgress: func,
  onEnablePIP: func,
  onDisablePIP: func,
  onBeforeManifestLoad: func,
  onAfterManifestLoad: func,
  onSubtitleProgress: func,
  fileExtension: string,
  currentTime: number,
  mediaType : string,
  barStatus : bool,
  subtitleSeek: number,
  // Add to filter out
  mediaResolution: object,
  isM3U8: bool
}

export const defaultProps = {
  playing: false,
  loop: false,
  controls: false,
  volume: null,
  muted: false,
  playbackRate: 1,
  width: '',
  height: '',
  style: {},
  progressInterval: 1000,
  playsinline: true,
  pip: false,
  light: false,
  wrapper: 'div',
  config: {
    file: {
      attributes: {
        controlsList: "nodownload"
      },
      tracks: [],
      forceVideo: false,
      forceAudio: false,
      forceHLS: false,
      forceDASH: false,
      hlsOptions: {},
      hlsVersion: '0.12.4',
      dashVersion: '2.9.2'
    }
  },
  onReady: function () {},
  onStart: function () {},
  onPlay: function () {},
  onPause: function () {},
  onBuffer: function () {},
  onBufferEnd: function () {},
  onEnded: function () {},
  onError: function () {},
  onDuration: function () {},
  onSeek: function () {},
  onProgress: function () {},
  onEnablePIP: function () {},
  onDisablePIP: function () {}
}
